import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const POChallanPdfPrinter = () => { 
    const exportToPDF = (company, product, barcode, qrcode, userName, btnFunc) => {
        const tableItems = product.general
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(product, tableItems, barcode, btnFunc),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10
            },
            info: {
                title: product.challan_number
            }
        }

        pushIntoTable(doc, product, tableItems, btnFunc)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }
    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60,
                    maxWidth: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }
    const getContent = (product, tableItems, barcode, btnFunc) => {
        return  [
            {
                text: 'DELIVERY CHALLAN',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: 'SHIP TO',
                                color: 'black',
                                bold: true,

                            },

                            {
                                text: `${product.contact.name}\n${(product.contact.profile_items.length > 0) ? product.contact.profile_items[0].value : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },
                        ]

                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: [
                                    {
                                        text: 'CHALLAN DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: formatToDdMmYy(product.challan_date),
                                    }
                                 ]
                            },

                            {
                                text: [
                                    {
                                        text: 'CHALLAN NO: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: product.challan_number
                                    }
                                 ]
                            },

                            {
                                svg: barcode
                            },

                            {
                                stack: [
                                    {
                                        text: product.location_id ? 'SHIP FROM:' : '',
                                        bold: true
                                    },

                                    {
                                        text: product.business_location ? product.business_location.name : ''
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: []
                },
                layout: {
			        fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
		        }
		    },

            {
                alignment: 'justify',
                text: [{ text: product.description ? 'NOTE: \n' : '', bold: true}, {text: product.description} ]
            }

        ]
    }
    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const pushIntoTable = (doc, product, tableItems, btnFunc) => {
        var flag = false;
        tableItems.forEach( (item, index) => {
            if( !flag ){
                const tableHeader = getTableHeader(btnFunc);
                doc.content[2].table.body.push(tableHeader);
                flag = true;
            }
            const rowData = getRowData(item);
            return doc.content[2].table.body.push(rowData);
        });
    }
    const getTableHeader = (btnFunc) => [{ text: 'PARTICULARS' },
        { text: 'UNIT' },
        { text: 'ORDER QTY', alignment: 'right' },
        { text: 'ALREADY DELIVERED QTY', alignment: 'right' },
        { text: 'DELIVERY QTY', alignment: 'right' },
        { text: 'REMARKS', alignment: 'right' }]
    const getRowData = (item) => [ { stack: [{text: item.product.name }]},
        { text: item.product.description.unit.name },
        { text: item.sales_order_general.quantity, alignment: 'right' },
        { text: item.delivered_qty, alignment: 'right' },
        { text: item.quantity, alignment: 'right' },
        { text: item.description }]

    return {
        exportToPDF
    }
}

export default POChallanPdfPrinter;
